<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'SubOptimalConsoleSettingsTrueview',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Line items with a Flight pacing setup',
          value: (alert) => {
            if (alert.data.no_respect) {
              const idList = alert.data.no_respect.map(li => li.id)
              if (idList.length > 0) {
                return `<br>- id: ${this.$commonUtils.htmlEntitiesList(idList).join('<br>- id: ')}`
              }
            }
            return null
          },
          optional: true
        }
      ],
      settingsData: [],
      algoStrategyData: []
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
